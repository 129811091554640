<template lang="pug">
	.card-basic.chouse-payment-system
		.d-flex.justify-content-between.mb-4.chouse-payment-system__steps(v-if="step > 0 && chousen != 3")
			span.chouse-payment-system__step(v-for="v in step == 1 && chousen == 3 ? 3 : 7" :class="{'active': v - 1 == stepDots, 'success': v - 1 <= stepDots - 1}")

		div.mb-4(v-if='step == 0')
			p.b1.mb-3 {{ $t('project.paymentAccounts.chouseType') }}

			.card-basic.chouse-payment-system__type(v-for='i in list' :key='i.id' :class='{"type-active" : isActive(i)}' @click='setActive(i)')
				.d-flex.justify-content-between.align-items-center
					.d-flex.flex-column
						span.b1.mb-2 {{ i.title }}
						span.b3.text-grey.mb-0(:class="{'b4': isMobile}") {{ i.desc }}
					.d-flex.ml-2
						img.chouse-payment-system__img(:src="isDarkColor ? `/assets/img/payment-system/${i.src}-dark.png` : `/assets/img/payment-system/${i.src}-light.png`")
						b-form-checkbox.visually-hidden(:checked='isActive(i)' v-if='isActive(i)' disabled)
		div(v-if='step == 1')
			div(v-if='chousen == 3')
				h2.b1.mb-3 {{ list[chousen-1].title }}
				p.b3.mb-3(v-html="$t('finance.paymentSystems[0].enterCardNumberDesc')")
				p.b3.text-grey.mb-3(v-html="description")
				rules-confirm(@isChecked='confirmRulesChaned')
			div(v-else)
				form-add-shop(@onSuccess='createAccountAndAddShop' @onBack='changeStep' :isOrganization='chousen == 1' :showRules='true' @stepNext="stepDots++" @stepPrev="stepDots--")

		.d-flex(v-if='!(step == 1 && chousen != 3)')
			.btn.btn-border.w-30.mr-3(@click='changeStep(false)' v-if='step != 0 && !(step == 0 && forceEditAccount)') {{$t('wizard.prev')}}
			.btn.btn-primary(@click='changeStep' :class="[{'disabled': isPending || (step == 1 && chousen == 3 && !isRulesChecked) || chousen == 0 || isloading}, step == 0 ? 'w-100' : 'w-70']")
				.loader(v-if="isloading")
				span(v-else) {{ step == 1 && chousen == 3 ? $t('forms.done') : $t('wizard.continue') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '@/main';
import RulesConfirm from '@/components/Form/RulesConfirm';
import FormAddShop from '@/components/Form/FormAddShop';
import { PAY_SYSTEM } from '@/models/account';
import { DEVICE } from '@/utils/device';
import { STORAGE } from '@/api/storage';

export default {
    name: 'FormChousePaymentSystem',
    components: {
        RulesConfirm,
        FormAddShop,
    },
    props: {
        editedItem: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isloading: false,
            stepDots: 0,
            checked: true,
            chousen: 0,
            isRulesChecked: false,
            step: 0,
            list: [],
            forceEditAccount: false,
            item: {},
            justAddedAccount: null,
            isSafari: DEVICE.isSafari(),
            isFirefox: DEVICE.isFirefox(),
        };
    },
    computed: {
        ...mapGetters({
            isPending: 'users/isPending',
        }),
        description() {
            return this.$t('finance.paymentSystems[0].addCardDesc2');
        },
    },
    methods: {
        ...mapActions({
            addAccount: 'finance/addAccount',
            editAccount: 'finance/editAccount',
            addCard: 'finance/addCard',
            getAccount: 'finance/accountId',
            addShop: 'finance/addShop',
            getAccounts: 'finance/accounts',
        }),
        setDefault() {
            this.chousen = 0;
            this.isRulesChecked = false;
            this.forceEditAccount = false;
            this.step = 0;

            if (this.$route && this.$route.query && this.$route.query.editAccount)
                return (this.forceEditAccount = true);
            this.forceEditAccount = false;

            if (STORAGE.getCardError()) {
                this.chousen = 3;
                this.step = 1;
            }
        },
        isActive(item) {
            return item.id == this.chousen;
        },
        setActive(item) {
            this.chousen = item.id;
            this.$emit('onConfirm', this.chousen);
        },
        async changeStep(next) {
            if (next && this.step == 0) this.isRulesChecked = false;

            if (next && this.step == 1 && this.chousen == 3) {
                if (!this.isRulesChecked) return false;
                return this.listen();
            }

            if (next && this.step == 2) {
                return this.validateEmail();
            }

            this.step += next ? 1 : -1;
            this.stepDots += next ? 1 : -1;

            if (this.step < 0) {
                this.$emit('onBack');
            }
        },
        confirmRulesChaned(v) {
            this.isRulesChecked = v;
        },
        onAddedCard() {
            this.$router.push({ query: {} });
            window.location.reload();
        },
        async createAccountAndAddShop(data) {
            let existAccount = _.find(this.me.accounts, a => a.isOOO() && !a.hasRequisite());

            if (existAccount) {
                this.addShopToAccount(data, existAccount);
                return;
            }

            if (this.editedItem && this.editedItem.id) {
                this.addShopToAccount(data, this.editedItem);
                return;
            }

            this.addAccount({ type: { value: PAY_SYSTEM[1] }, attachToAll: true, shop: data })
                .then(a => {
                    STORAGE.setFinance(true);
                    this.onAddedCard();
                })
                .catch(v => {
                    this.$notify('error', v);
                });
        },

        async addShopToAccount(data, a) {
            data.accountId = a.id || a['@id'];

            this.addShop(data)
                .then(i => {
                    if (!this.me || this.me.isPartner) this.editAccount({ id: data.accountId, attachToAll: true });
                    STORAGE.setFinance(true);

                    if (this.me && this.me.isPartner) {
                        this.$router.push({ name: 'finance' });
                        window.location.reload();
                        return;
                    }

                    this.$router.push({ query: {} });
                    window.location.reload();
                })
                .catch(v => {
                    this.$notify('error', v);
                });
        },
        async listen() {
            this.isloading = true;

            this.addAccount({ type: { value: PAY_SYSTEM[2] } })
                .then(a => {
                    STORAGE.setFinance(true);
                    this.onAddedCard();
                })
                .catch(v => {
                    this.$notify('error', v);
                });
        },
    },
    created() {
        this.list = [
            {
                id: 2,
                title: this.$t('finance.accountSubTypeINDIVIDUAL'),
                desc: this.$t('project.paymentAccounts.ip'),
                src: 'ip',
            },
            {
                id: 1,
                title: this.$t('finance.accountSubTypeORGANIZATION'),
                desc: this.$t('project.paymentAccounts.ooo'),
                src: 'ul',
            },
            {
                id: 3,
                title: this.$t('finance.accountSubTypeFIZ'),
                desc: this.$t('project.paymentAccounts.fiz'),
                src: 'fl',
            },
        ];

        if (this.me && this.me.isPartner) this.list = _.filter(this.list, v => v.id != 3);

        this.setDefault();
    },
    watch: {
        list(v) {
            if (v) {
                this.setDefault();
            }
        },
        $route(v) {
            if (v && v.query && v.query.editAccount) return (this.forceEditAccount = true);
            this.forceEditAccount = false;
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.chouse-payment-system {
    &__steps {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, var(--foreground-color) 40%, var(--border-line) 40%);
            background-size: 28px 1px;
            background-repeat: repeat-x;
            transform: translateY(-50%);
        }
    }

    &__step {
        position: relative;
        z-index: 1;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--border-line);

        &.active {
            background-color: var(--brand-warning);
        }

        &.success {
            background-color: var(--light-green);
        }
    }

    &__type {
        padding: 10px 43px 10px 20px !important;
        border: 1.5px solid transparent;
        box-shadow: var(--card-box-shadow) !important;
        transition: var(--animation-time-short);
        margin-bottom: 20px;
        cursor: pointer;

        &:hover,
        &:active {
            box-shadow: var(--box-shadow-hover) !important;
        }

        @include respond-below(sm) {
            padding: 20px !important;
            margin-bottom: 15px;
        }

        &.type-active {
            border: 1.5px solid #0071f4;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        width: 95px;
        height: 95px;
    }
}
</style>
