<template lang="pug">
	.requisite-bik
		p.b1.mb-3 {{ $t('errors.required.bikOOO') }}

		my-input(
			v-model="$v.bik.$model"
			:label="$t('forms.placeholder.bik')"
			:icon="'icon-bank'"
			:classInput="{'is-error': alreadyAttemptSend && $v.bik.$error || error, 'is-success-icon': data && data.bik && $v.bik.required && $v.bik.numeric && $v.bik.minLength && $v.bik.maxLength}"
			mask='#########'
			@input="onPaste"
		)
		.text-danger.b4.mt-1(v-if='!$v.bik.required && alreadyAttemptSend') {{ $t('errors.required.bik') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bik.numeric && alreadyAttemptSend') {{ $t('errors.numeric.default') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bik.minLength && alreadyAttemptSend') {{ $t('errors.minLength.bik') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bik.maxLength && alreadyAttemptSend') {{ $t('errors.minLength.bik') }}
		.text-danger.b4.mt-1(v-else-if='error') {{ error }}

		.requisite-loader(v-if='!data.bik')
			loader(v-if='isPending')
		.mb-4(v-else)
			p.b1.text-grey.mt-3 {{ $t('forms.clickNextBank') }}
			.card-info(v-if='data.bik')
				span.text-uppercase.b3.font-bold-600.mb-1 {{ data.fullname }}
				span.text-uppercase.b3.mb-1(v-if='data.address') {{ data.address }}
				span.b3.mb-1 {{ $t('forms.placeholder.bik') }} {{ data.bik }}
				span.b3.mb-1 {{ $t('forms.placeholder.inn') }} {{ data.inn }}
				span.b3 {{ $t('finance.corAccount2') }} {{ data.corAccount }}
</template>

<script>
const { required, numeric, minLength, maxLength } = require('vuelidate/lib/validators');

import Loader from '@/components/Widgets/Loader';
import { mapActions, mapGetters } from 'vuex';
import { bus } from '@/main.js';
import MyInput from '@/components/UI/MyInput';

export default {
    name: 'RequisiteBik',
    components: {
        Loader,
        MyInput,
    },
    data() {
        return {
            bik: null,
            error: null,
            alreadyAttemptSend: false,
            data: {
                fullname: null,
                shortName: null,
                bik: null,
                inn: null,
                ogrn: null,
                kpp: null,
                corAccount: null,
                address: null,
                addressesStreet: null,
                addressesCity: null,
                addressesZip: null,
            },
        };
    },
    validations() {
        return {
            bik: { required, numeric, minLength: minLength(9), maxLength: maxLength(9) },
        };
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
        }),
    },
    methods: {
        ...mapActions({
            findBank: 'finance/findBank',
        }),
        async validateForm() {
            this.$v.$touch();
            this.alreadyAttemptSend = true;

            if (this.$v.$anyError) return false;

            this.find();
            return true;
        },

        onPaste() {
            setTimeout(() => this.validateForm(), 100);
        },
    },
    created() {
        bus.$on('validateInn', this.validateForm);
        this.find = _.debounce((data = {}) => {
            this.error = null;
            _.each(this.data, (d, i) => (this.data[i] = null));

            this.findBank({ query: this.bik })
                .then(v => {
                    this.$emit('onFind', null);

                    if (v.length == 0) return (this.error = this.$t('errors.noFound.bank'));

                    let value = v[0].data;

                    this.data.fullname = v[0].value;
                    this.data.shortName = value.name.short;
                    this.data.inn = value.inn;
                    this.data.bik = value.bic;
                    this.data.kpp = value.kpp;
                    this.data.address = value.address.value;
                    this.data.addressesStreet = value.address.data.unrestricted_value;
                    this.data.addressesCity = value.address.data.city_with_type
                        ? value.address.data.city_with_type
                        : value.address.data.settlement_with_type;
                    this.data.addressesZip = value.address.data.postal_code;
                    this.data.corAccount = value.correspondent_account;

                    this.$emit('onFind', this.data);
                })
                .catch(v => {
                    this.error = v;
                    this.$emit('onFind', null);
                });
        }, 500);
    },
    destroyed() {
        bus.$off('validateInn', this.validateForm);
    },
};
</script>
