<template lang="pug">
	.requisite-addons
		p.b1.mb-3 {{ $t('forms.enterAddons') }}

		div(v-if='!list.addressesStreet || !list.addressesZip || list.needEditAddress')
			my-input-dropdown.inactive-shop__dropdown(
				v-model="addressesStreet"
				:list="addressList"
				:icon="'icon-house'"
				:label='(list.type && list.type.value === "INDIVIDUAL") ? $t("forms.placeholder.ownerAddress") : $t("forms.placeholder.companyAddress")'
				@input='searchAddress'
				@select='afterSelectAddress'
				@onClear='afterSelectAddress'
				@blur='validWatchAddress(addressesStreet)'
				:isError='error'
				:class='{"mb-4" : (!$v.addressesStreet.$invalid || !$v.addressesStreet.$dirty) && !error }'
			)
			.text-danger.b4.mb-2.mt-1(v-if='$v.addressesStreet.$dirty && !$v.addressesStreet.zipInAddress || error') {{ $t('errors.required.zipInAddress') }}

		div(v-if='list.needEditLastName')
			my-input(
				@blur='validateForm($v.lastName)'
				v-model.trim="$v.lastName.$model"
				:label="$t('forms.placeholder.surname')"
				:icon="'icon-profile'"
				:classInput="{'is-error': $v.lastName.$error}"
				:class="$v.lastName.$error > 0 ? 'mb-1' : 'mb-4'"
			)
			.text-danger.b4.mb-2(v-if='$v.lastName.$dirty && !$v.lastName.required') {{ $t('errors.required.surname') }}
			.text-danger.b4.mb-2(v-else-if='$v.lastName.$dirty && !$v.lastName.kiril') {{ $t('errors.valid.kirilOnly') }}
			.text-danger.b4.mb-2(v-else-if='$v.lastName.$dirty && !$v.lastName.minLength') {{ $t('errors.minLength.surname') }}
			.text-danger.b4.mb-2(v-else-if='$v.lastName.$dirty && !$v.lastName.maxLength') {{ $t('errors.maxLength.lastName') }}

		div(v-if='list.needEditFirstName')
			my-input(
				@blur='validateForm($v.firstName)'
				v-model.trim="$v.firstName.$model"
				:label="$t('forms.placeholder.givenName')"
				:icon="'icon-profile'"
				:classInput="{'is-error': $v.firstName.$error}"
				:class="$v.firstName.$error > 0 ? 'mb-1' : 'mb-4'"
			)
			.text-danger.b4.mb-2(v-if='$v.firstName.$dirty && !$v.firstName.required') {{ $t('errors.required.givenName') }}
			.text-danger.b4.mb-2(v-else-if='$v.firstName.$dirty && !$v.firstName.kiril') {{ $t('errors.valid.kirilOnly') }}
			.text-danger.b4.mb-2(v-else-if='$v.firstName.$dirty && !$v.firstName.minLength') {{ $t('errors.minLength.name') }}
			.text-danger.b4.mb-2(v-else-if='$v.firstName.$dirty && !$v.firstName.maxLength') {{ $t('errors.maxLength.firstName') }}

		div(v-if='list.needEditMiddleName')
			my-input(
				@blur='validateForm($v.middleName)'
				v-model.trim="$v.middleName.$model"
				:label="$t('forms.placeholder.middleName')"
				:icon="'icon-profile'"
				:classInput="{'is-error': $v.middleName.$error}"
				:class="$v.middleName.$error > 0 ? 'mb-1' : 'mb-4'"
			)
			.text-danger.b4.mb-2(v-if='$v.middleName.$dirty && !$v.middleName.required') {{ $t('errors.required.middleName') }}
			.text-danger.b4.mb-2(v-else-if='$v.middleName.$dirty && !$v.middleName.kiril') {{ $t('errors.valid.kirilOnly') }}
			.text-danger.b4.mb-2(v-else-if='$v.middleName.$dirty && !$v.middleName.minLength') {{ $t('errors.minLength.middleName') }}
			.text-danger.b4.mb-2(v-else-if='$v.middleName.$dirty && !$v.middleName.maxLength') {{ $t('errors.maxLength.middleName') }}

		my-input(
			@blur='validateForm($v.email)'
			v-model.lazy="$v.email.$model"
			:label="$t('forms.emailOOO')"
			:icon="'icon-sms'"
			:type="'email'"
			:classInput="{'is-error': $v.email.$dirty && $v.email.$invalid}"
			:class="$v.email.$dirty && $v.email.$invalid > 0 ? 'mb-1' : 'mb-4'"
		)

		.text-danger.b4.mb-2(v-if='$v.email.$dirty && !$v.email.required') {{ $t('errors.required.email') }}
		.text-danger.b4.mb-2(v-else-if='$v.email.$dirty && !$v.email.emailCustom') {{ $t('errors.valid.email') }}

		my-input(
			@blur='validateForm($v.phone)'
			v-model="$v.phone.$model"
			:label="$t('forms.placeholder.phoneOOO')"
			:icon="'icon-call'"
			:type="'tel'"
			v-mask="'+7 (###) ###-##-##'"
			:classInput="{'is-error': $v.phone.$error}"
			:class="$v.phone.$error > 0 ? 'mb-1' : 'mb-4'"
		)
		.text-danger.b4.mb-2(v-if='$v.phone.$dirty && !$v.phone.required') {{ $t('errors.required.phone') }}
		.text-danger.b4.mb-2(v-else-if='$v.phone.$dirty && !$v.phone.phone') {{ $t('errors.numeric.phone') }}

		.d-flex.flex-column.mb-4
			p.b3.text-grey.mb-3 {{ $t('forms.placeholder.birthdayOOO') }}
			pw-select-birthday(@success='changedBirthday' @failed='cleanBirthday' @undef='fnUndefBirthday' :date='birthDate')
			.text-danger.b4.mt-1.mb-2(v-if='$v.birthDate.$dirty && !$v.birthDate.required') {{ $t('errors.required.birthday') }}
			.text-danger.b4.mt-1.mb-2(v-else-if='undefBirthday') {{ $t('errors.required.birthday') }}
</template>

<script>
const { required, numeric, minLength, maxLength, email } = require('vuelidate/lib/validators');

import Loader from '@/components/Widgets/Loader';
import { mapActions, mapGetters } from 'vuex';
import { locale } from '@/main';
import { en, ru } from 'vuejs-datepicker/dist/locale';
import PwSelectBirthday from '@/components/Form/PwSelectBirthday.vue';
import { SERVICE_STRING } from '@/utils/string';
import MyInput from '@/components/UI/MyInput';
import MyInputDropdown from '@/components/UI/MyInputDropdown';

const phone = value => {
    return SERVICE_STRING.isValidPhone(value);
};

const kiril = value => {
    if (value == null) return false;
    if (_.some(value.split('-'), v => v == '')) return false;
    if (_.some(value.split('—'), v => v == '')) return false;

    return /^([а-яА-ЯёЁ\-\—\s\.])+$/.test(value);
};

const zipInAddress = value => {
    return /\d{6}/.test(value);
};

const emailCustom = value => {
    return SERVICE_STRING.isValidEmail(value);
};

export default {
    components: {
        Loader,
        PwSelectBirthday,
        MyInput,
        MyInputDropdown,
    },
    props: {
        list: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            firstName: null,
            middleName: null,
            lastName: null,
            fullAddress: null,
            email: null,
            phone: '+7 (',
            birthDate: null,
            error: null,
            addressesStreet: null,
            locale: locale,
            l: { en: en, ru: ru },
            openDate: new Date(2000, 10, 10),
            disabledDates: {
                from: new Date(new Date().getFullYear() - 18, new Date().getMonth() - 1, new Date().getDate()),
            },
            addressList: [],
            undefBirthday: false,
        };
    },
    validations() {
        let v = {
            email: { required, emailCustom },
            phone: { required, phone },
            birthDate: { required },
            firstName: { required, kiril, minLength: minLength(2), maxLength: maxLength(50) },
            middleName: { required, kiril, minLength: minLength(2), maxLength: maxLength(50) },
            lastName: { required, kiril, minLength: minLength(2), maxLength: maxLength(50) },
            addressesStreet: { required, zipInAddress },
        };

        if (this.list && this.list.addressesStreet && this.list.addressesZip && !this.list.needEditAddress)
            delete v.addressesStreet;
        if (this.list && !this.list.needEditFirstName) delete v.firstName;
        if (this.list && !this.list.needEditMiddleName) delete v.middleName;
        if (this.list && !this.list.needEditLastName) delete v.lastName;

        return v;
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
        }),
        errorAddress() {
            return (
                this.$v.addressesStreet.$invalid &&
                this.$v.addressesStreet.$dirty &&
                !this.$v.addressesStreet.zipInAddress
            );
        },
    },
    methods: {
        ...mapActions({
            getAddress: 'finance/findAddress',
        }),
        changedBirthday(date) {
            this.birthDate = date;
            this.undefBirthday = false;
            this.validateForm(this.$v.birthDate);
        },

        cleanBirthday() {
            this.birthDate = null;
            this.undefBirthday = false;
            this.validateForm(this.$v.birthDate);
        },

        fnUndefBirthday() {
            this.undefBirthday = false;
            this.birthDate = null;

            this.validateForm(this.$v.birthDate);
        },
        async validateForm(field) {
            field.$touch();

            let p = {
                email: this.email,
                phone: this.phone,
                birthDate: this.birthDate,
            };

            if (this.fullAddress) {
                p.addressesStreet = _.compact([
                    this.fullAddress.data.street_with_type
                        ? this.fullAddress.data.street_with_type
                        : this.fullAddress.data.settlement_with_type,
                    this.fullAddress.data.house_type
                        ? this.fullAddress.data.house_type + ' ' + this.fullAddress.data.house
                        : null,
                    this.fullAddress.data.flat_type
                        ? this.fullAddress.data.flat_type + ' ' + this.fullAddress.data.flat
                        : null,
                ]).join(', ');
                p.addressesCity = _.uniq(
                    _.compact([
                        this.fullAddress.data.region_with_type,
                        this.fullAddress.data.area_with_type,
                        this.fullAddress.data.city_with_type,
                        this.fullAddress.data.settlement_with_type,
                    ])
                ).join(', ');
                p.addressesZip = this.fullAddress.data.postal_code
                    ? this.fullAddress.data.postal_code
                    : this.list.addressesZip
                      ? this.list.addressesZip
                      : null;
                p.addressShown = this.addressesStreet;
            }

            if (this.firstName) p.firstName = this.firstName;
            if (this.middleName) p.middleName = this.middleName;
            if (this.lastName) p.lastName = this.lastName;

            if (this.$v.firstName && this.$v.firstName.$invalid) p.firstName = 'null';
            if (this.$v.middleName && this.$v.middleName.$invalid) p.middleName = 'null';
            if (this.$v.lastName && this.$v.lastName.$invalid) p.lastName = 'null';
            if (this.$v.phone && this.$v.phone.$invalid) p.phone = '+7 (';
            if (this.$v.email && this.$v.email.$invalid) p.email = 'null';
            if (this.$v.addressesStreet && this.$v.addressesStreet.$invalid) {
                p.addressesStreet = 'null';
                p.addressesCity = 'null';
                p.addressesZip = 'null';
                p.addressShown = 'null';
            }

            this.$emit('onConfirm', p);
            return true;
        },

        setDefault() {
            this.email = this.list ? this.list.email : null;
            this.phone = this.list && this.list.phone ? this.list.phone : '+7 (';
            this.birthDate = this.list ? this.list.birthDate : null;
            this.addressesStreet =
                this.list && this.list.address && this.list.address != 'null' ? this.list.address : null;
            this.setAddress();
            this.lastName = this.list ? this.list.lastName : null;
            this.firstName = this.list ? this.list.firstName : null;
            this.middleName = this.list ? this.list.middleName : null;
            this.error = null;
        },
        setAddress() {
            if (this.addressesStreet) {
                this.getAddress({ query: this.addressesStreet }).then(vv => {
                    this.addressList = vv;
                    this.afterSelectAddress(this.addressesStreet);
                });
            }
        },
        afterSelectAddress(v) {
            this.error = null;
            this.addressesStreet = v;
            this.fullAddress = _.find(this.addressList, a => a.unrestricted_value == v);
            this.validateForm(this.$v.addressesStreet);
        },
        validWatchAddress(v) {
            this.afterSelectAddress(v);

            if (this.fullAddress == null) {
                this.error = true;
            } else if (v != this.fullAddress.unrestricted_value) {
                this.error = true;
            } else if (v == this.fullAddress.unrestricted_value) {
                this.error = null;
            } else if (v == null || v == '') {
                this.error = null;
            } else {
                null;
            }
        },
    },
    created() {
        this.setDefault();

        this.searchAddress = _.debounce(query => {
            if (query && query.length < 3) return;

            this.getAddress({ query: query }).then(vv => {
                this.addressList = vv;
            });
        }, 400);
    },
    watch: {
        list(v) {
            if (v) {
                this.setDefault();
            }
        },
        addressesStreet(v) {
            this.afterSelectAddress(v);
            this.validWatchAddress(v);
        },
    },
};
</script>
