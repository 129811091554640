<template lang="pug">
	.requisite-account-number
		.mb-4
			.disclaimer.warning.b3.mb-4 {{ $t('finance.attentionBankRequisites') }}

			p.b1.mb-2 {{ $t('finance.bankRequisitesTitle') }}

			.requisite-account-number__card
				span {{ $t('forms.accountNumber2') }}
				span.text-break {{ data.bankAccountNumber }}
			.requisite-account-number__card
				span {{ $t('forms.placeholder.bank') }}
				span.text-break {{ data.bankAccountName }}
			.requisite-account-number__card
				span {{ $t('forms.placeholder.bik') }}
				span.text-break {{ data.bankAccountBik }}
			.requisite-account-number__card
				span {{ $t('finance.corAccount2') }}
				span.text-break {{ data.bankCorrAccountNumber }}
</template>

<script>
const { required, numeric, minLength, maxLength } = require('vuelidate/lib/validators');

import { mapActions } from 'vuex';
import { bus } from '@/main.js';
import MyInput from '@/components/UI/MyInput';

const individual = value => {
    return !/^40817/.test(value);
};

export default {
    name: 'RequisiteAccountNumber',
    components: {
        MyInput,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            bankAccountNumber: null,
            alreadyAttemptSend: false,
            alreadyPassData: false,
        };
    },
    validations() {
        return {
            bankAccountNumber: {
                required,
                numeric,
                minLength: minLength(20),
                maxLength: maxLength(20),
                individual: individual,
            },
        };
    },
    methods: {
        ...mapActions({
            findBank: 'finance/findBank',
        }),
        validateForm() {
            this.$v.$touch();
            this.alreadyAttemptSend = true;

            if (this.$v.$anyError) return false;

            this.$emit('onSuccess', this.$v.bankAccountNumber.$model);
            this.alreadyPassData = true;
            return true;
        },
        onPaste() {
            if (this.isMobile) setTimeout(() => this.validateForm(), 100);
        },
    },
    created() {
        bus.$on('validateBankAccountNumber', this.validateForm);
    },
    destroyed() {
        bus.$off('validateBankAccountNumber', this.validateForm);
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.requisite-account-number {
    &__card {
        display: flex;
        align-items: center;
        min-height: 46px;
        padding: 12px 0;
        border-bottom: 1px solid var(--grayscale-border);

        @include respond-below(sm) {
            flex-direction: column;
            align-items: start;
            padding: 15px 0;
        }

        span {
            &:first-child {
                width: 49%;
                font-size: 14px;
                line-height: 22px;
                color: var(--grey-text-color);
                margin-right: 6px;

                @include respond-below(sm) {
                    width: 100%;
                    margin: 0 0 10px;
                }
            }

            &:last-child {
                width: 51%;
                font-size: 14px;
                line-height: 22px;
                border-bottom: none;

                @include respond-below(sm) {
                    width: 100%;
                }
            }
        }
    }

    .border-none {
        border-bottom: none;
    }
}
</style>
